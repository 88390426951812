var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items.items,"item-key":"id","loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.items.meta.totalItems,"footer-props":{
      'items-per-page-options': _vm.rowsPerPageItemsGlobal
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":_vm.$lang.labels.name,"data-cy":"instances-name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',{staticClass:"pb-2"},[_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.sendQuery()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v("# "+_vm._s(item.id))])]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$lang.status[item.type]))])]}},{key:"item.createdOn",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDateTimePrecise")(item.createdOn)))])]}},{key:"item.heartbeat",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDateTimePrecise")(item.heartbeat)))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"color":"primary"},on:{"click":function($event){_vm.stats = item.threadsStats; _vm.heartbeat = item.heartbeat; _vm.statsShow = true}}},[_vm._v(_vm._s(_vm.$lang.actions.stats))])]}}])}),_c('v-snackbar',{attrs:{"color":_vm.snackColor,"content-class":"text-center","top":""},model:{value:(_vm.snackShow),callback:function ($$v) {_vm.snackShow=$$v},expression:"snackShow"}},[_c('span',{staticClass:"color-accent-text"},[_vm._v(_vm._s(_vm.snackbarText))])]),(_vm.statsShow)?_c('v-dialog',{attrs:{"max-width":"50%"},model:{value:(_vm.statsShow),callback:function ($$v) {_vm.statsShow=$$v},expression:"statsShow"}},[_c('stats-log',{attrs:{"stats":_vm.stats,"heartbeat":_vm.heartbeat},on:{"closeDialog":function($event){_vm.stats = null; _vm.statsShow = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }