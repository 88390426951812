<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <v-row no-gutters class="pt-2">
          <v-col cols="5" class="pl-2">
            <v-text-field
              v-model="name"
              outlined
              dense
              :label="$lang.labels.name"
              data-cy="instances-name"
            ></v-text-field>
          </v-col>
          <div class="pb-2">
            <v-btn
              text
              color="primary"
              class="ml-2"
              @click="sendQuery()"
            ><v-icon>mdi-refresh</v-icon></v-btn>
          </div>
        </v-row>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.type="{ item }">
        <div>{{ $lang.status[item.type] }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTimePrecise }}</div>
      </template>

      <template v-slot:item.heartbeat="{ item }">
        <div>{{ item.heartbeat | formatDateTimePrecise }}</div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="primary" class="mx-0" @click="stats = item.threadsStats; heartbeat = item.heartbeat; statsShow = true">{{ $lang.actions.stats }}</v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackShow"
      :color="snackColor"
      content-class="text-center"
      top
    >
      <span class="color-accent-text">{{ snackbarText }}</span>
    </v-snackbar>
    <v-dialog v-if="statsShow" v-model="statsShow" max-width="50%">
      <stats-log
        :stats="stats"
        :heartbeat="heartbeat"
        @closeDialog="stats = null; statsShow = false"
      ></stats-log>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
import statsLog from './LogStats'

export default {
  components: {
    statsLog
  },
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      snackbarText: '',
      snackShow: false,
      snackColor: 'success',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$lang.labels.type, value: 'type' },
        { text: this.$lang.labels.createdOn, value: 'createdOn' },
        { text: this.$lang.labels.heartbeat, value: 'heartbeat' },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      name: '',
      lock: false,
      stats: null,
      heartbeat: '',
      statsShow: false
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userSettings'])
  },
  watch: {
    searchProcesses: {
      handler(val) {
        if (val && val.length > 1) {
          this.searchProcessesFunction(val)
        }
      }
    },
    options: {
      handler () {
        this.sendQuery()
      },
      deep: true
    },
    name: {
      handler () {
        this.sendQuery()
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.sendQuery()
    })
  },
  created() {

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    if (this.$route && this.$route.query && this.$route.query.name) {
      this.lock = true
      this.name = this.$route.query.name
      this.loadPreFill()
    } else {
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (preFill && preFill.instancesList) {
          this.lock = true
          this.name = preFill.instancesList.name
          this.loadPreFill()
        }
      }
    }
  },
  methods: {
    showSnack(text, color = 'success') {
      this.snackbarText = text
      this.snackColor = color
      this.snackShow = true
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.instancesList) preFill.instancesList = {
          name: ''
        }

        preFill.instancesList.name = this.name

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    loadPreFill() {
      this.lock = false
      this.savePreFill()
      this.sendQuery()
    },
    sendQuery() {
      this.$emit('fetchLogs', { options: this.options, name: this.name  })
      this.savePreFill()
    }
  }
}
</script>
<style lang="scss" scoped>
.clickable {
  cursor: pointer;
  text-decoration: underline;
  color: var(--v-primary-base);
}
</style>
