<template>
  <div>
    <h1 class="text-h4">{{ $lang.routes.instances }}</h1>
    <v-divider class="my-2"></v-divider>
    <v-row v-if="err || success" dense no-gutters class="px-3">
      <v-col v-if="err" cols="12">
        <v-alert color="error" :data-cy="err">{{ err }}</v-alert>
      </v-col>
      <v-col v-if="success" cols="12">
        <v-alert color="success" :data-cy="success">{{ success }}</v-alert>
      </v-col>
    </v-row>
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :items="logs"
          :loading="loading"
          @fetchLogs="fetchLogs($event)"
        ></Table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './LogsInstancesDatatable'
import {
  getInstancesUsingGET as getLogs
} from '@/utils/api'

export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      tableKey: 0,
      loading: false,
      logs: { items: [], meta: {} }
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.success = this.$route.params.message
      setTimeout(() => this.success = '', 5000)
    }
  },
  methods: {
    fetchLogs(options) {
      this.err = ''

      const obj = {}

      if (options) {
        if (options.name && options.name.length > 1) obj.name = options.name
        if (options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      this.loading = true
      getLogs(obj)
        .then((res) => {
          this.logs = res.data.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.err = error
          setTimeout(() => this.err = '', 5000)
          console.log(error)
        })
    }
  }
}
</script>
