<template>
  <v-card class="pa-2" style="max-height: 80vh; overflow-y: auto">
    <v-card-title class="pb-2">
      <span>{{ $lang.labels.threadStatistics }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        color="primary"
        text
        large
        @click="$emit('closeDialog', true)"
      >
        X
      </v-btn>
    </v-card-title>
    <v-row no-gutters>
      <v-col cols="12">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              text
              large
              v-bind="attrs"
              v-on="on"
              @click="clipboardCopy(JSON.stringify(stats))"
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>{{ $lang.labels.copy }}</span>
        </v-tooltip>
        <v-row no-gutters class="pt-3">
          <v-col cols="12" class="mb-3">
            <div>{{ $lang.labels.lastUpdateHeartbeat }}: {{ heartbeat | formatDateTimePrecise }}</div>
          </v-col>
          <v-col v-if="stats.http" cols="4">
            <h3 class="mb-2">{{ $lang.labels.http }}</h3>
            <p>{{ $lang.labels.available }}: <strong>{{ stats.http.available }}</strong></p>
            <p>{{ $lang.labels.used }}: <strong>{{ stats.http.used }}</strong></p>
          </v-col>
          <v-col v-if="stats.internalJobs" cols="4">
            <h3 class="mb-2">{{ $lang.labels.internalJobs }}</h3>
            <p>{{ $lang.labels.available }}: <strong>{{ stats.internalJobs.available }}</strong></p>
            <p>{{ $lang.labels.used }}: <strong>{{ stats.internalJobs.used }}</strong></p>
          </v-col>
          <v-col v-if="stats.processing" cols="4">
            <h3 class="mb-2">{{ $lang.labels.processing }}</h3>
            <p>{{ $lang.labels.available }}: <strong>{{ stats.processing.available }}</strong></p>
            <p>{{ $lang.labels.used }}: <strong>{{ stats.processing.used }}</strong></p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    stats: {
      type: Object,
      default: () => {
      }
    },
    heartbeat: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      tab: 0,
      tempArr: [],
      success: ''
    }
  },
  created() {},
  methods: {
    clipboardCopy(type) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard

      clipboardData.writeText(type)

      this.success = this.$lang.success.copiedClipboard

      setTimeout(() => this.success = '', 5000)
    }
  }
}
</script>
<style lang="scss" scoped>
p {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
